import React from "react";
import { Section } from "./Section";

export const Products = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <Section
      urlId="products"
      ref={ref}
      isWhite
      title="We offer robust solutions"
      description="At Nastech, we take pride in the work we do and the things we make.
    
    Our platforms and services are some of the most stable and complete
    products available in the market."
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <p className="text-blue-900 font-bold text-xl">Nastech vStores</p>
          <p className="text-gray-800 text-lg">
            Our POS system is a full-featured, easy to use and robust solution
            that can cater to both small stores and large marts. With integrated
            order, inventory, vendor, employee managment, and reports, our
            system provides a complete set of features to painlessly run your
            retail operations.
          </p>
        </div>
        <div>
          <p className="text-blue-900 font-bold text-xl">Nastech vCommerce</p>
          <p className="text-gray-800 text-lg">
            vCommerce is our e-commerce solution that can cater to small and
            emerging businesses that quickly want to take their operations
            online into the digital world with minimum effort.
            <br />
            <br />
            With integrated order, inventory, employee managment and reporting,
            vCommerce offers and easy to use and flexible online retail solution
            without the associated complexities of other e-commerce systems.
          </p>
        </div>
      </div>
    </Section>
  );
});
