import React, { useEffect, useRef } from "react";
import useScrollSpy from "react-use-scrollspy";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Wave } from "./components/Wave";
import { Products } from "./components/Products";
import { ContactForm } from "./components/ContactForm";
import { Services } from "./components/Services";
import { Hero } from "./components/Hero";
import { Navbar } from "./components/Navbar";
import { CurrentCourses } from "./components/CurrentCourses";
import { Clients } from "./components/Clients";

const sectionName = [
    "",
    "services",
    "courses",
    "products",
    "clients",
    "contact-us",
];

function App()
{
    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -300,
    });

    useEffect(() =>
    {
        document.location.replace(`#${sectionName[activeSection]}`);
    }, [activeSection]);

    return (

        <div className="leading-normal tracking-normal gradient">
            <TawkMessengerReact
                propertyId="645f3bd774285f0ec46b36e6"
                widgetId="1h0a03gll" />
            <Navbar activeSection={activeSection} />
            <Hero ref={sectionRefs[0]} />
            <Wave />
            <Services ref={sectionRefs[1]} />

            <div className="border-b border-gray-200 w-full"></div>

            <CurrentCourses ref={sectionRefs[2]} />

            <div className="border-b border-gray-200 w-full"></div>

            <Products ref={sectionRefs[3]} />
            <div className="border-b border-gray-200 w-full"></div>

            <Clients ref={sectionRefs[4]} />

            <Wave isReversed />
            <ContactForm ref={sectionRefs[5]} />
            <div className="w-full py-3 md:mt-24 mt-12 text-gray-100 gradient">
                <div className="container max-w-6xl mx-auto m-2 px-8">
                    © {new Date().getFullYear()} Nastech Ltd. All rights reserved.
                </div>
            </div>

        </div>
    );
}

export default App;
