import React, { useState } from "react";
import heroImage from "../images/hero.png";
import { validateEmail } from "../utils";

export const Hero = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [email, setEmail] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const validate = () => {
    return validateEmail.test(email);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(false);
  };

  const handleRegister = (e: React.MouseEvent) => {
    setLoading(true);
    if (!validate()) {
      setError(true);
      setLoading(false);
      return;
    }

    console.log("button clicked with email", email);
    setLoading(false);
  };

  return (
    <div id="home" className="pt-24 text-white" ref={ref}>
      <div className="container md:px-3 px-8 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex flex-col w-full md:w-2/5 justify-center items-center md:items-start text-center md:text-left">
          <h1 className="my-4 text-5xl font-bold leading-tight">
            Ready when you are
          </h1>
          <p className="leading-normal text-2xl mb-6">
            Experts in cutting-edge technologies with proficiency in IT
            infrastructure, Networking, Cloud Computing, Software Development
            and DevOps ready to assist you instantly. Your success is our
            satisfaction.
          </p>

          <div className="flex items-center justify-center my-3">
            <input
              onChange={handleInput}
              value={email}
              className="w-full bg-gray-100 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              autoComplete="email"
              placeholder="clark.kent@daily-planet.com"
            />

            <button
              onClick={handleRegister}
              disabled={isLoading}
              className="transition-colors inline-flex items-center disabled:bg-gray-500 disabled:opacity-50 mx-2 bg-blue-800 hover:bg-blue-700 text-white font-bold rounded-lg py-3 px-6 shadow-lg"
            >
              {isLoading && (
                <svg
                  className="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Register
            </button>
          </div>
          {isError && (
            <p className="text-gray-100 opacity-90 text-sm italic -mt-1">
              Please enter a valid email.
            </p>
          )}
        </div>
        <div className="w-full md:w-3/5 py-6 text-center">
          <img
            className="w-full md:w-4/5 z-50"
            src={heroImage}
            alt="Read when you are!"
          />
        </div>
      </div>
    </div>
  );
});
