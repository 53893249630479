import React, { useState } from "react";
import contactImage from "../images/contact-us.svg";
import { SubmitForm } from "../services/userService";
import { validateEmail } from "../utils";

export const ContactForm = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const validate = () => {
    let isValid = true;
    const { name, email, message } = formState;

    if (!name) {
      isValid = false;
      setFormErrors((prevState) => ({ ...prevState, name: true }));
    }

    if (!email || !validateEmail.test(email)) {
      isValid = false;
      setFormErrors((prevState) => ({ ...prevState, email: true }));
    }

    if (!message) {
      isValid = false;
      setFormErrors((prevState) => ({ ...prevState, message: true }));
    }

    return isValid;
  };

  const handleInputChange =
    (key: keyof typeof formState) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
      setFormErrors((prevState) => ({ ...prevState, [key]: false }));
    };

  const handleSubmit = async () => {
    setLoading(true);

    if (!validate()) {
      setLoading(false);

      return;
    }

    const response = await SubmitForm(formState);
    if (response) {
      // do something with response
    }

    console.log("submit");
    setLoading(false);
  };

  return (
    <div
      id="contact-us"
      ref={ref}
      className="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 md:rounded-lg shadow-lg"
    >
      <div className="flex flex-col justify-between">
        <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
          Lets talk!
        </h2>

        <div className="my-6">
          <div className="mb-2">
            <p className="text-md font-bold text-blue-900">Headquarters</p>
            <p className="text-gray-800 ml-2">
              B-61, Block 10, Federal B. Area, <br />
              Karachi, Pakistan - 75950
            </p>
          </div>

          <div className="my-2">
            <p className="text-md font-bold text-blue-900">Phone</p>
            <p className="text-gray-800 ml-2">+92 333 925 97 54</p>
          </div>

          <div className="mt-2">
            <p className="text-md font-bold text-blue-900">Support</p>
            <p className="text-gray-800 ml-2">
              <a href="mailto:info@nastechltd.co">info@nastechltd.co</a>
            </p>
          </div>
        </div>

        <div className="text-center">
          <img src={contactImage} alt="contact us" />
        </div>
      </div>
      <div className="">
        <div>
          <span className="uppercase text-sm text-gray-600 font-bold">
            Full Name
          </span>
          <input
            required
            value={formState.name}
            onChange={handleInputChange("name")}
            className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder=""
          />
        </div>
        {formErrors.name && (
          <p className="text-gray-800 opacity-70 italic text-sm">
            Please enter a valid name.
          </p>
        )}
        <div className="mt-6">
          <div>
            <span className="uppercase text-sm text-gray-600 font-bold">
              Email
            </span>
            <input
              required
              value={formState.email}
              onChange={handleInputChange("email")}
              className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
            />
          </div>
          {formErrors.email && (
            <p className="text-gray-800 opacity-70 italic text-sm">
              Please enter a valid email.
            </p>
          )}
        </div>
        <div className="mt-6">
          <span className="uppercase text-sm text-gray-600 font-bold">
            Subject
          </span>
          <input
            value={formState.subject}
            onChange={handleInputChange("subject")}
            className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mt-6">
          <span className="uppercase text-sm text-gray-600 font-bold">
            How can we help?
          </span>
          <textarea
            required
            value={formState.message}
            onChange={handleInputChange("message")}
            className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
          ></textarea>
        </div>
        {formErrors.message && (
          <p className="text-gray-800 opacity-70 italic text-sm">
            Please enter a valid message.
          </p>
        )}

        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="inline-flex items-center justify-center disabled:bg-gray-500 disabled:opacity-50 uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
          >
            {isLoading && (
              <svg
                className="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
});
