import api from "./api";

export const SubmitForm = (data: {
  name: string;
  email: string;
  subject?: string;
  message: string;
}) => {
  return api.post("contact/", data);
};
