import React from "react";
import { Section } from "./Section";
import geoLocationImage from "../images/geo-location.svg";
import mobileImage from "../images/mobile-design.svg";
import CMSImage from "../images/cms.svg";
import educationImage from "../images/studying.svg";
import ERPImage from "../images/erp.svg";
import dataStreamingImage from "../images/cloud.svg";
import { ServiceBox } from "./ServiceBox";

export const Services = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <Section
      urlId="services"
      ref={ref}
      title="Services we offer"
      description="Developing solutions that fulfill requirements, are secure, robust,
  future-proof, high performance and cost-effective is merely a chest of
  what we do."
      isWhite
    >
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 xs:grid-cols-1">
        <ServiceBox img={ERPImage} title="ERP Solutions" />
        <ServiceBox img={CMSImage} title="CRM and CMS Solutions" />
        <ServiceBox
          img={educationImage}
          title="Educational Platform Solutions"
        />
        <ServiceBox img={dataStreamingImage} title="Data Streaming Solutions" />
        <ServiceBox img={geoLocationImage} title="Geo Location Solutions" />
        <ServiceBox img={mobileImage} title="Mobile and Web Applications" />
      </div>
    </Section>
  );
});
