import React, { useState } from "react";
import { Section } from "./Section";

export const CurrentCourses = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (tabIndex: number) => () => {
    setTab(tabIndex);
  };

  const courses = {
    infra: [
      "RHCSA - Red Hat Certified Systems Administrator",
      "RHCE [RHEL 7] - Red Hat Certified Engineer for Red Hat Enterprise Linux 7",
      "RHCE [RHEL 8] - Red Hat Certified Engineer for Red Hat Enterprise Linux 8",
    ],
    networking: ["CCNA Security", "CCNA Routing & Switching"],
    others: ["AWS CSA - Amazon Web Services Certified Solutions Architect"],
  };

  return (
    <Section
      isWhite
      urlId="courses"
      ref={ref}
      title="Currently Offered Courses"
    >
      <div className="items-center justify-center">
        <div id="tablist" className="flex items-center justify-start">
          {["All", "Infrastructre & Systems", "Networking"].map((t, i) => (
            <button
              key={t}
              onClick={handleTabChange(i)}
              className={`${
                tab === i
                  ? "border-b-blue-900 border-b-2 hover:rounded-none"
                  : ""
              } hover:bg-blue-700/10 hover:rounded-lg mr-2 outline-none px-6 py-3 text-blue-900 text-xl transition-colors`}
            >
              {t}
            </button>
          ))}
        </div>

        <div id="tabpanels" className="my-3 rounded-md">
          {tab === 0 &&
            Object.values(courses)
              .flat()
              .map((course) => (
                <p key={course} className="my-3 font-medium text-xl">
                  {course}
                </p>
              ))}

          {tab === 1 &&
            courses.infra.map((course) => (
              <p key={course} className="my-3 font-medium text-xl">
                {course}
              </p>
            ))}
          {tab === 2 &&
            courses.networking.map((course) => (
              <p key={course} className="my-3 font-medium text-xl">
                {course}
              </p>
            ))}
        </div>
      </div>
    </Section>
  );
});
