import React from "react";

export const Section = React.forwardRef<
  HTMLDivElement,
  {
    title: string;
    description?: string;
    children: React.ReactNode;
    isWhite?: boolean;
    urlId: string;
  }
>(({ title, description, urlId, children, isWhite = false }, ref) => {
  return (
    <section
      id={urlId}
      ref={ref}
      className={isWhite ? "bg-gray-100 py-8" : "py-8 text-white"}
    >
      <div className="container max-w-6xl mx-auto m-8 px-8">
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center ${
            isWhite ? "text-blue-900" : "text-white"
          }`}
        >
          {title}
        </h1>
        <div className="w-full mb-4">
          <div
            className={`h-1 mx-auto ${
              isWhite ? "gradient" : "gradient-light"
            } w-64 opacity-25 my-0 py-0 rounded-t`}
          ></div>
        </div>
        <p
          className={
            isWhite
              ? "text-gray-600 mb-8 text-center"
              : "text-white mb-8 text-center"
          }
        >
          {description}
        </p>

        {children}
      </div>
    </section>
  );
});
