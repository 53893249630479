import React from "react";

export const ServiceBox: React.FC<{ title: string; img: string }> = ({
  title,
  img,
}) => {
  return (
    <div className="flex flex-col text-center items-center justify-end">
      <img className="w-64" src={img} alt={title} />
      <p className="text-gray-800 font-bold text-lg px-6 mt-5">{title}</p>
    </div>
  );
};
