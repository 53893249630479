import { forwardRef } from "react";
import { Section } from "./Section";

import uDriveLogo from "../images/udrive-logo.png";
import FMELogo from "../images/fme-logo.png";
import MKSLogo from "../images/munirkhanani-logo.png";
import WGSLogo from "../images/wgs-logo.png";

export const Clients = forwardRef<HTMLDivElement>((props, ref) => {
  const clients = [
    {
      name: "uDrive",
      logo: uDriveLogo,
      description: [
        "Developed the core architecture for a vehicle tracking and control system to manage telemetry data across the entire fleet of UDrive vehicles.",
        "Provided Linux consulting and support for UDrive server running on AWS.",
      ],
    },
    {
      name: "Flowmotion Entertainment",
      logo: FMELogo,
      description: [
        "Provided consulting and support for Flowmotion's database management including setup of High Availability architecture.",
        "Tuning of the database to meet the demands of a growing customer base.",
        "Provided consulting for migrating a monolithic on-premises architecture to a microservices based architecture running in the cloud.",
      ],
    },
    {
      name: "Munir Khanani Solutions",
      logo: MKSLogo,
      description: [
        "Provided consulting and deployment services to setup a virtualization cluster built on VMWare with High Availability and Fault Tolerance.",
        "Designed and deployed a complete HA setup for their stocks trading system compliant with the complex financial regulations.",
        "Deployed a highly available network infrastructure and application cluster based on Linux.",
      ],
    },
    {
      name: "Wonderland Grammar School",
      logo: WGSLogo,
      description: [
        "Developed a school management solution for automating and managing day to day operations of the school including the admission process, fee management, accounts management, HR and Payroll.",
      ],
    },
  ];

  return (
    <Section title="Clients" ref={ref} isWhite urlId="clients">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {clients.map((client) => (
          <div className="border shadow-lg rounded-md px-6 py-6 flex flex-col items-center justify-center">
            <div className="flex items-center justify-center mb-6">
              <img src={client.logo} alt={client.name} />
            </div>
            <ul className="text-gray-600 font-sm">
              {client.description.map((d) => (
                <li className="mb-2">{d}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Section>
  );
});
