import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../images/logo.svg";

export const Navbar = ({ activeSection = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setScroll] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 64) {
        setScroll(true);
        // setClasses("fixed w-full z-30 top-0 bg-gray-100 shadow-lg");
      } else {
        setScroll(false);
        // setClasses("fixed w-full z-30 top-0");
      }
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <nav
      id="header"
      className={`transition-colors fixed w-full z-30 top-0 ${
        hasScrolled && "bg-gray-100 shadow-lg"
      }`}
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
        <div className="pl-4 flex items-center">
          <a
            className="no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            href="/"
          >
            <Logo
              className={`h-8 ${hasScrolled ? "fill-[#2c5294]" : "fill-white"}`}
            />
          </a>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            onClick={handleMenuClick}
            className={`flex items-center hover:text-gray-900 hover:bg-gray-100 rounded-lg p-3 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out
            ${hasScrolled ? "text-black" : "text-gray-100"}
            `}
          >
            <svg
              className="fill-current h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent p-4 lg:p-0 z-20 ${
            !isOpen && "hidden"
          }`}
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 0 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="/"
              >
                Home
              </a>
            </li>
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 1 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="#services"
              >
                Services
              </a>
            </li>
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 2 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="#courses"
              >
                Courses
              </a>
            </li>
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 3 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="#products"
              >
                Products
              </a>
            </li>
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 4 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="#clients"
              >
                Clients
              </a>
            </li>
            <li className="mr-3">
              <a
                className={`inline-block py-2 px-4 no-underline ${
                  activeSection === 5 && "font-bold"
                } ${hasScrolled || isOpen ? "text-black" : "text-gray-100"}`}
                href="#contact-us"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
